import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Content from './Content';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './stateProvider/stateProvider';
import { initialState } from './reducer/init';
import reducer from './reducer/reducer';


const server = 'https://panel-preview.herokuapp.com'
 //const server = 'http://localhost:5000';


ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
 <Content/>
  </StateProvider>
 ,
  document.getElementById('root')
);

reportWebVitals();
